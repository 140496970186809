import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompaniesComponent } from './views/companies/companies.component';
import { GuestsComponent } from './views/guests/guests.component';
import { PosComponent } from './views/pos/pos.component';
import { ReservationComponent } from './views/reservationdata/reservation/reservation.component';
import { ReservationdataComponent } from './views/reservationdata/reservationdata/reservationdata.component';
import { ReportsComponent } from './views/reports/reports.component';
import { PosReportsComponent } from './views/pos-reports/pos-reports.component';
import { RoomviewComponent } from './views/roomview/roomview.component';
import { ExpensesComponent } from './views/expenses/expenses.component';
import { HomeComponent } from './views/store/home/home.component';
import { ContactlessCheckinComponent } from './views/contactless-checkin/contactless-checkin.component';
import { GroupBookingComponent } from './views/group-booking/group-booking.component';
import { CityLedgerComponent } from './views/companies/city-ledger/city-ledger.component';
import { MaintenanceHomeComponent } from './views/maintenance/maintenance-home/maintenance-home.component';
import { Stayview2Component } from './views/stayview2/stayview2/stayview2.component';
import { LeadsListComponent } from './views/leads/leads-list/leads-list.component';
import { CreateLeadComponent } from './views/leads/create-lead/create-lead.component';
import { HrHomeComponent } from './views/hr-module/hr-home/hr-home.component';
import { AccountsIndexComponent } from './views/accounts/accounts-index/accounts-index.component';
import { AccountsLedgerComponent } from './views/accounts/accounts-ledger/accounts-ledger.component';
import { OnboardComponent } from './views/onboard/onboard.component';
import { TaxLedgerComponent } from './views/accounts/taxes/tax-ledger/tax-ledger.component';
import { MaintenanceIndexComponent } from './views/maintenance/maintenance-index/maintenance-index.component';
import { PmsOpsComponent } from './views/pms-ops/create-pms-ops/pms-ops.component';
import { PmsOpsDataComponent } from './views/pms-ops/pms-ops-data/pms-ops-data.component';
import { BanquetComponent } from './views/banquets/banquet/banquet.component';
import { CreateBanquetReservationComponent } from './views/banquets/create-banquet-reservation/create-banquet-reservation.component';
import { BulkOutOfOrderComponent } from './views/reservationdata/bulk-out-of-order/bulk-out-of-order.component';
import { DashboardComponent } from './views/dashboard/dashboard/dashboard.component';
import { LogsComponent } from './views/logs/logs.component';
import { HsnSummaryComponent } from './views/accounts/taxes/hsn-summary/hsn-summary.component';
import { B2binvoiceSummaryComponent } from './views/accounts/taxes/b2binvoice-summary/b2binvoice-summary.component';
import { DocSummaryComponent } from './views/accounts/taxes/doc-summary/doc-summary.component';
import { B2cSummaryComponent } from './views/accounts/taxes/b2c-summary/b2c-summary.component';
import { NilInvoiceDetailsComponent } from './views/accounts/taxes/nil-invoice-details/nil-invoice-details.component';
import { PosTableViewComponent } from './views/pos/pos-table-view/pos-table-view.component';
import { PosCreateOrderComponent } from './views/pos/pos-create-order/pos-create-order.component';
import { PosOrderViewComponent } from './views/pos/pos-order-view/pos-order-view.component';
import { PaymentLinksComponent } from './views/payment-links/payment-links/payment-links.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'stay-view/:hotelId',
    pathMatch: 'full',
    data: { title: 'Home' },
  },
  {
    path: 'stay-view/:hotelId',
    component: Stayview2Component,
  },
  {
    path: 'reservation/:hotelId',
    component: ReservationComponent,
  },
  {
    path: 'reservation-data/:hotelId',
    component: ReservationdataComponent,
  },
  // {
  //   path: 'guests/:hotelId',
  //   loadChildren: () => import('./views/guests/guests.module').then(m => m.GuestsModule)
  // },
  // {
  //   path: 'companies/:hotelId',
  //   loadChildren: () => import('./views/companies/companies.module').then(m => m.CompaniesModule)
  // }
  {
    path: 'guests/:hotelId',
    component: GuestsComponent,
  },
  {
    path: 'companies/:hotelId',
    component: CompaniesComponent,
  },
  {
    path: 'city-ledger/:hotelId',
    component: CityLedgerComponent,
  },
  {
    path: 'pos/:hotelId',
    component: PosComponent,
  },
  {
    path: 'reports/:hotelId',
    component: ReportsComponent,
    data: { title: 'Reports' },
  },
  {
    path: 'pos-reports/:hotelId',
    component: PosReportsComponent,
    data: { title: 'Pos-Reports' },
  },
  {
    path: 'room-view/:hotelId',
    component: RoomviewComponent,
  },
  {
    path: 'expenses/:hotelId',
    component: ExpensesComponent,
  },
  {
    path: 'stores/:hotelId',
    component: HomeComponent,
  },
  {
    path: 'contactless-checkin/:hotelId/:bookingId',
    component: ContactlessCheckinComponent,
  },
  {
    path: 'group-booking/:hotelId',
    component: GroupBookingComponent,
  },
  {
    path: 'maintenance/:hotelId',
    component: MaintenanceHomeComponent,
  },
  {
    path: 'maintenance-group/:hotelId',
    component: MaintenanceIndexComponent,
  },
  {
    path: 'leads/:hotelId',
    component: LeadsListComponent,
  },
  {
    path: 'leads/:hotelId/create',
    component: CreateLeadComponent,
  },
  {
    path: 'leads/:hotelId/:leadId',
    component: CreateLeadComponent,
  },
  {
    path: 'hr-module/:hotelId',
    component: HrHomeComponent,
  },
  {
    path: 'accounts/:hotelId',
    component: AccountsIndexComponent,
  },
  {
    path: 'accounts/ledger/:hotelId/:id',
    component: AccountsLedgerComponent,
  },
  {
    path: 'accounts/taxes/ledger/:hotelId',
    component: TaxLedgerComponent,
  },
  {
    path: 'accounts/taxes/hsn-summary',
    component: HsnSummaryComponent
  },
  {
    path: 'accounts/taxes/b2b-summary',
    component: B2binvoiceSummaryComponent
  },
  {
    path: 'accounts/taxes/b2c-summary',
    component: B2cSummaryComponent
  },
  {
    path: 'accounts/taxes/doc-summary',
    component: DocSummaryComponent
  },
  {
    path: 'accounts/taxes/nil-invoice-details',
    component: NilInvoiceDetailsComponent
  },
  {
    path: 'onboarding',
    component: OnboardComponent,
    data: { title: 'Quick Onboarding' },
  },
  {
    path: 'create-pms-operations-entry/:hotelId',
    component: PmsOpsComponent,
  },
  {
    path: 'pms-operations-data/:hotelId',
    component: PmsOpsDataComponent
  },
  {
    path: 'banquet/:hotelId',
    component: BanquetComponent
  },
  {
    path: 'banquet/:hotelId/create',
    component: CreateBanquetReservationComponent
  },
  {
    path: 'bulk-out-of-order/:hotelId',
    component: BulkOutOfOrderComponent
  },
  {
    path: 'dashboard/:hotelId',
    component: DashboardComponent
  },
  {
    path: 'pos-logs/:hotelId',
    component: LogsComponent
  },
  {
    path: 'pos-table-view/:hotelId',
    component: PosTableViewComponent
  },
  {
    path: 'pos-create-order/:hotelId',
    component: PosCreateOrderComponent
  },
  {
    path: 'pos-order-view/:hotelId',
    component: PosOrderViewComponent
  },
  {
    path: 'payment-links/:hotelId',
    component: PaymentLinksComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
