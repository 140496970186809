<div>
    <div class="aio_heading">
        <h2>Payment Links</h2>
    </div>

    <mat-divider></mat-divider>

    <div class="container-fluid common_content">
        <div class="input_group">
            <div>
                <label for="">Amount</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="amount" 
                    (keypress)="globals.numberWithDecimal($event)">
            </div>

            <div>
                <label for="">Invoice No.</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="invoiceId">
            </div>

            <div class="action-btns">
                <button mat-raised-button class="primary_btn" (click)="updateUrl()">
                    Show Link
                </button>
                
                <button mat-raised-button class="primary_btn" (click)="openLinkDialog()">
                    Copy Link
                </button>
            </div>
        </div>

        <div class="iframe-container">
            <iframe
                [src]="safeUrl"
                width="100%"
                height="700"
                frameborder="0">
            </iframe>

        </div>
    </div>
</div>