<div class="container">
    <div>
        <h1 class="title">{{data.title}}
        </h1>
    </div>
    <div class = "row" >
        <div class="col-sm-12 mb-2 deleteReason">
            <label for="comments">{{data.subTitle}} :</label>
            <input type="text" class="form-control" [(ngModel)]="comments" id="comments">
        </div>
    </div>
    <div class="row action-buttons">
        <button mat-dialog-close="true"
            mat-raised-button>{{'stayview.payment_detail.cancel' | translate}}
        </button>
        <button type="submit" mat-raised-button mat-dialog-close="{{comments}}"
            [disabled]="comments==''"
            class="confirm-button" >
            {{'stayview.payment_detail.submit' | translate}}
        </button>
    </div>
</div>