import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Globals } from 'src/app/core/globals.service';
import { StoreService } from 'src/app/core/store.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-payment-links',
  templateUrl: './payment-links.component.html',
  styleUrls: ['./payment-links.component.scss']
})
export class PaymentLinksComponent implements OnInit {

  iframeUrl: string;
  safeUrl: SafeResourceUrl;
  hotelId: string;
  invoiceId: string = '';
  amount: string;
  hotelName: string = '';

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public globals: Globals,
    private storeService: StoreService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const routeSnap = this.route.snapshot;
    this.hotelId = routeSnap.params?.hotelId;
    this.getHotel();
  }

  getHotel() {
    this.storeService.getHotel(this.hotelId).subscribe(res => {
      this.hotelName = res?.globals?.name;
      this.updateUrl();
    })
  }

  updateUrl() {
    this.iframeUrl = `https://live.aiopay.in/payment/pay?client_id=aiosell-pms&property_id=${this.hotelId}&receiver_name=${encodeURIComponent(this.hotelName)}`;
    if (this.amount) {
      this.iframeUrl += `&amount=${this.amount}`;
    }
    if (this.invoiceId) {
      this.iframeUrl += `&invoice_id=${this.invoiceId}`;
    }
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
  }

  openLinkDialog() {
    this.updateUrl();
    const dialogBody =
    {
      title: 'Payment Link',
      innerHTML: `<p style="overflow-wrap: anywhere; margin: 0;">${this.iframeUrl}</p>`,
      cancelBtn: 'Ok',
      height: '200px'
    };

    this.dialog.open(DialogComponent, {
      data: dialogBody,
    })
  }
}
