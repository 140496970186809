<div class="box-menu">
    <div class="more-info">
      <div>
        <div class="guest-info">
          <div class="name">
            <mat-icon>supervisor_account</mat-icon>
            <h4>{{guestInfo.guestName}}</h4>
            <a style="color: white;" class="ml-auto" (click)="fetchLogs(guestInfo.roomAllocationId)">
              <u>Logs</u>
            </a>
          </div>
  
          <div class="row" style="margin: auto;">
            <div class="col-sm-6 phone">
              <mat-icon>phone</mat-icon>
              <h5>{{guestInfo?.guestContact?.phone}}</h5>
            </div>
  
            <div class="col-sm-6 email">
              <mat-icon>email</mat-icon>
              <h5 class="text-truncate">{{guestInfo?.guestContact?.email}}</h5>
            </div>
          </div>
        </div>
  
        <div class="room-info">
          <div class="row" style="margin: auto;">
            <div class="col-sm-6">
              <div class="first-col">
                <div class="row" style="margin: auto;">
                  <div class="col-sm-6"><small>{{'stayview.module.check_in/out' | translate}}</small></div>
                  <div class="col-sm-6">
                    <small style="font-weight: 650;" *ngIf="guestInfo.checkIn && guestInfo.checkOut">
                      : {{guestInfo.checkIn | date: 'dd MMM' }} - {{guestInfo.checkOut | date: 'dd MMM'}}
                    </small>
                  </div>
                </div>
                <div class="row mt-2" style="margin: auto;">
                  <div class="col-sm-6">
                    <small>{{'stayview.module.source' | translate}}</small>
                  </div>
                  <div class="col-sm-6">
                    <small style="font-weight: 650;">: {{guestInfo.source}}</small>
                  </div>
                </div>
                
                <div class="row mt-2" style="margin: auto;">
                  <div class="col-sm-6">
                    <small>{{'stayview.module.room_type' | translate}}</small>
                  </div>
                  <div class="col-sm-6">
                    <small style="font-weight: 650;">: {{guestInfo.roomDisplayName}}</small>
                  </div>
                </div>

                <div class="row mt-2" style="margin: auto;">
                  <div class="col-sm-6">
                    <small>{{'stayview.module.no_of_guest' | translate}}</small>
                  </div>
                  <div class="col-sm-6">
                    <small style="font-weight: 650;">: {{guestInfo.pax}}</small>
                  </div>
                </div>

                <div class="row mt-2" style="margin: auto;">
                  <div class="col-sm-6">
                    <small>{{'stayview.module.meal_plan' | translate}}</small>
                  </div>
                  <div class="col-sm-6">
                    <small style="font-weight: 650;">
                      : {{rateplanId}}
                    </small>
                  </div>
                </div>

                <div class="row mt-2" style="margin: auto;">
                  <div class="col-sm-6">
                    <small>{{'stayview.module.payment_mode' | translate}}</small>
                  </div>
                  <div class="col-sm-6">
                    <small style="font-weight: 650;">: {{guestInfo?.paymentMode}}</small>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-sm-6" style="padding: 5px; border: 1px solid #587bbd2c;">
              <div class="row" style="margin: auto;">
                <div class="col-sm-6">
                  <small>{{'stayview.module.special_request' | translate}} :</small>
                </div>
              </div>
              <div class="row" style="margin: auto;">
                <div class="col">
                  <small class="text-secondary">{{guestInfo.room?.specialRequest}}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="sec-col">
        <div class="row" style="margin: auto;">
            <small class="col-sm-3">{{'stayview.module.amount' | translate}}</small>
            <small class="col-sm-3">
              : {{totalAmount}}
            </small>
          <ng-container>
            <small class="col-sm-3">{{'stayview.module.balance' | translate}}</small>
            <small class="col-sm-3">: {{balance}}</small>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="actions">
      <button 
        (click)="goToEdit(guestInfo.roomAllocationId, guestInfo.status, guestInfo.invoiceIds, guestInfo.bookingId, guestInfo.source)" 
        mat-raised-button>
        {{'stayview.module.edit' | translate}}
      </button>

      <button *ngIf=" guestInfo.status === 'Assigned'"
        (click)="deleteAllocation(guestInfo.bookingId, guestInfo.checkIn, guestInfo.checkOut, guestInfo.totalAmount)"
        [ngClass]="{'disable_button': !features?.live?.deleteReservation}"
        mat-raised-button>
        {{'stayview.module.delete' | translate}}
      </button>

      <button *ngIf="guestInfo.status === 'Out of Order'"
        (click)="deleteOutOfOrder(guestInfo.checkIn, guestInfo.checkOut)"
        mat-raised-button>
        {{'stayview.module.delete' | translate}}
      </button>

      <button (click)="getInvoice(guestInfo.roomAllocationId, guestInfo.status)"
        *ngIf="(guestInfo.status == 'Checked in') || (guestInfo.status == 'Checked out') || (guestInfo.status == 'Assigned')" 
        mat-raised-button>
        {{'stayview.module.invoice' | translate}}
      </button>

      <button (click)="unassignRoom(guestInfo.checkIn, guestInfo.checkOut, guestInfo.roomAllocationId)"
        *ngIf="(guestInfo.status == 'Assigned')"
        mat-raised-button>
        {{'stayview.module.unassign' | translate}}
      </button>

      <button
        (click)="goToAddService()"
        *ngIf="guestInfo.status == 'Checked in' && !(guestInfo.status == 'Maintainence')"
        mat-raised-button
        [ngClass]="{'disable_button': features?.live?.hasOwnProperty('addService') && !features.live.addService}">
        {{'stayview.module.add_service' | translate}}
      </button>

      <button *ngIf="guestInfo.status == 'Checked in' || guestInfo.status == 'Assigned'"
        (click)="goToAddPayment(guestInfo.roomAllocationId, guestInfo.invoiceIds)"
        mat-raised-button
        [ngClass]="{'disable_button': !features?.live?.addPayment}"
        >
        {{'stayview.module.add_payment' | translate}}
      </button>

      <button 
        (click)="checkOut(guestInfo.roomAllocationId, guestInfo.bookingId)"
        *ngIf="guestInfo.status == 'Checked in' && !(guestInfo.status == 'Out of Order')"
        mat-raised-button
        [ngClass]="{'disable_button': !features?.live?.checkout}"
        >
        {{'stayview.module.check_out' | translate}}
      </button>

      <button 
        (click)="undoCheckOut(guestInfo.roomAllocationId, guestInfo.bookingId)"
        *ngIf="guestInfo.status == 'Checked out' && !(guestInfo.status == 'Out of Order') && guestInfo.checkOut == currentData.currentDate"
        mat-raised-button
        [ngClass]="{'disable_button': !features?.live?.undoCheckout}"
        >
        Undo Checkout
      </button>

      <button
        (click)="checkIn(guestInfo.roomAllocationId, guestInfo.bookingId)" 
        *ngIf="(guestInfo.status == 'Assigned') && guestInfo.checkIn == currentData.currentDate"
        mat-raised-button
        [ngClass]="{'disable_button': !features?.live?.checkin}"
        >
        {{'stayview.module.check_in' | translate}}
      </button>

      <button
        (click)="undoCheckIn(guestInfo.roomAllocationId, guestInfo.checkIn)" 
        *ngIf="(guestInfo.status == 'Checked in') && guestInfo.checkIn == currentData.currentDate"
        mat-raised-button
        [ngClass]="{'disable_button': !features?.live?.undoCheckin}"
        >
        {{'stayview.module.undo_check_in' | translate}}
      </button>

      <button
        (click)="editInvoice(guestInfo.roomAllocationId)" 
        *ngIf="(guestInfo.status == 'Checked out')"
        mat-raised-button>
        {{'stayview.module.edit_invoice' | translate}}
      </button>

      <button
        (click)="swapRoom(guestInfo.roomAllocationId)" 
        *ngIf="(guestInfo.status == 'Checked in')"
        mat-raised-button>
        {{'stayview.module.exchange_room' | translate}}
      </button>

      <button
        (click)="openEInvoice()" 
        *ngIf="hasEInvoice"
        mat-raised-button>
        E-Invoice
      </button>

      <button
        (click)="sendPaymentLink()" 
        *ngIf="(guestInfo.status ==='Checked in' && balance > 0) || guestInfo.status=='Assigned' || guestInfo.status=='Unassigned'"
        mat-raised-button>
        Send Payment Link
      </button>

      <button
        *ngIf="markNoShowEligible"
        (click)="markNoShow()"
        mat-raised-button>
        Mark No Show
      </button>

    </div>
  </div>