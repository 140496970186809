<div class="dialog-box-popup">
    <div class="row mb-1">
        <h1>{{'stayview.edit_invoice.title' | translate}}</h1>
        <hr>
    </div>
    <div *ngIf="data.status != 'checked-in'">
        <button *ngFor="let item of data.data; let i=index"
            mat-raised-button type="button" class="small-btn"
            [ngClass]="{'active': invoiceIndex === i}"
            (click)="changeInvoice(i)">
            {{item.invoice_num}}
        </button>
    </div>
    <div class="popup-form">
        <form [formGroup]="editInvoiceForm" (ngSubmit)="submit(editInvoiceForm)">
            <ng-container formArrayName="invoiceForm" 
            *ngFor="let room of editInvoiceForm.get('invoiceForm')['controls']; let i=index">
                <ng-container [formGroupName]="i"
                *ngIf="invoiceIndex === i">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="billTo">{{'stayview.edit_invoice.bill_to' | translate}}</label>
                            <input type="text" class="form-control" maxlength="50" 
                            formControlName="invoice_name">
                        </div>
        
                        <div class="col-sm-6">
                            <label for="gstNumber">{{taxTextToDisplay}}</label>
                            <input type="text" class="form-control" maxlength="50" 
                            formControlName="gst_num">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-8">
                            <label for="address">{{'stayview.edit_invoice.address' | translate}}</label>
                            <textarea type="text" class="form-control" id="address" maxlength="200" 
                            formControlName="invoice_address"></textarea>
                        </div>

                        <div class="col-sm-4">
                            <label for="state">{{'stayview.edit_invoice.state' | translate}}</label>
                            <input type="text" class="form-control" id="state" maxlength="60" 
                            formControlName="state">
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="row action-buttons mt-4">
                <button type="button" mat-raised-button mat-dialog-close
                (click)="closeDialog()">
                    {{'stayview.edit_invoice.cancel_btn' | translate}}
                </button>
                <button type="submit" mat-raised-button mat-dialog-close
                    class="confirm-button">
                    {{'stayview.edit_invoice.submit_btn' | translate}}
                </button>
            </div>
        </form>
    </div>
</div>