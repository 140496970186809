<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Quick Reservation</h1>

        <svg [mat-dialog-close]="false" role="button"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" />
        </svg>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">

        <form [formGroup]="quickForm">

            <div class="section">
                <div>
                    <label>Checkin </label>
                    <input type="date" class="form-control form-control-sm" formControlName="checkin" readonly>
                </div>
    
                <div>
                    <label>Checkout </label>
                    <input type="date" class="form-control form-control-sm" formControlName="checkout" min="{{data.reservation.checkin}}">
                </div>
    
                <div>
                    <label>#Guest </label>
                    <select class="form-control form-control-sm" formControlName="pax">
                        <option *ngFor="let n of roomTypeInfo.numGuestsAllowed"
                            [value]="n">{{n}}
                        </option>
                    </select>
                </div>
    
                <div>
                    <label>Rateplan </label>
                    <select class="form-control form-control-sm" formControlName="mealplan">
                        <option *ngFor="let mp of roomTypeInfo.mealplans"
                            [value]="mp">{{mp}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="section">
                <div>
                    <label>Guest Name </label>
                    <input type="text" class="form-control form-control-sm" maxlength="64" formControlName="guestName">
                </div>

                <div>
                    <label>Email </label>
                    <input type="email" class="form-control form-control-sm" maxlength="64" formControlName="email">
                </div>

                <div>
                    <label>Phone </label>
                    <input type="tel" class="form-control form-control-sm" maxlength="64" formControlName="phone">
                </div>
            </div>

            <div class="section">
                <div>
                    <div id="tax-block">
                        <label>Rate (per day): </label>

                        <label>Tax Inclusive: 
                            <input type="checkbox" formControlName="taxInclusive">
                        </label>
                    </div>
                    <input type="text" class="form-control form-control-sm" maxlength="64" formControlName="perDayRate"
                        (keypress)="numberOnly($event)"
                        [readOnly]="!data?.features?.addRate">
                </div>

                <div>
                    <label>Payment Mode </label>
                    <select class="form-control form-control-sm" formControlName="paymentMode">
                        <option *ngFor="let mp of infoData?.paymentModes"
                            [value]="mp">{{mp}}
                        </option>
                    </select>
                </div>

                <div>
                    <label>Total </label>
                    <input type="text" class="form-control form-control-sm" maxlength="64" formControlName="total" readonly>
                </div>
            </div>

        </form>
   
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button
                [disabled]="quickForm.invalid || !data?.features?.createReservation || roomTypeInfo.availableRooms <= 0"
                class="primary_btn"
                (click)="submit()">
                {{submitting ? 'Submitting...' : 'Submit'}}
            </button>
        </mat-dialog-actions>
    </div>

</div>