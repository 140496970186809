import { NgModule } from '@angular/core';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './_shared/header/header.component';
import { SidebarComponent } from './_shared/sidebar/sidebar.component';
import { Globals } from './core/globals.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import { ReservationComponent } from './views/reservationdata/reservation/reservation.component';
import { ReservationdataComponent } from './views/reservationdata/reservationdata/reservationdata.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatDialogModule} from '@angular/material/dialog';
import { DialogComponent } from './_shared/dialog/dialog.component';
import { SnackbarComponent } from './_shared/snackbar/snackbar.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { BrowserModule, Title } from '@angular/platform-browser';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { EditStayComponent } from './views/stayview/edit/edit-stay/edit-stay.component';
import { ServiceDetailsComponent } from './views/stayview/service-details/service-details.component';
import { PaymentsDetailsComponent } from './views/stayview/payment-details/payment-details.component';

import { StayviewModule } from './views/stayview/stayview.module';
import { EditModule } from './views/stayview/edit/edit.module';
import { GuestsModule } from './views/guests/guests.module';
import { BookingDetailsModule } from './views/booking-details/booking-details.module';
import { CompaniesModule } from './views/companies/companies.module';
import { PosModule } from './views/pos/pos.module';

import { SpinnerComponent } from './_shared/spinner/spinner.component';
import { InterceptorService } from './core/interceptor.service';
import { ReportsModule } from './views/reports/reports.module';
import { PosReportsModule } from './views/pos-reports/pos-reports.module';
import { RoomviewModule } from './views/roomview/roomview.module';
import { TokenInterceptorService } from './core/token-interceptor.service';
import { ExpensesComponent } from './views/expenses/expenses.component';
import { StoreModule } from './views/store/store.module';
import { AllocationServicesComponent } from './views/stayview/allocation-services/allocation-services.component';
import { ContactlessCheckinComponent } from './views/contactless-checkin/contactless-checkin.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { GroupBookingModule } from './views/group-booking/group-booking.module';
import { AdditionalGuestComponent } from './views/additional-guest/additional-guest.component';
import { MaintenanceModule } from './views/maintenance/maintenance.module';
import { AddServiceComponent } from './_shared/common_modals/add-service/add-service.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LogsComponent } from './views/logs/logs.component';
import { ChargeCardComponent } from './_shared/common_modals/charge-card/charge-card.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Stayview2Module } from './views/stayview2/stayview2.module';
import { LineChartComponent } from './views/charts/line-chart/line-chart.component';
import { ChartsModule } from 'ng2-charts';
import { EditBookingIdComponent } from './views/reservationdata/edit-booking-id/edit-booking-id.component';
import { LeadsModule } from './views/leads/leads.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EmployeesComponent } from './views/hr-module/employees/employees.component';
import { AttendanceComponent } from './views/hr-module/attendance/attendance.component';
import { SalaryComponent } from './views/hr-module/salary/salary.component';
import { HrHomeComponent } from './views/hr-module/hr-home/hr-home.component';
import { CreateEmployeeComponent } from './views/hr-module/employees/create-employee/create-employee.component';
import { AccountsModule } from './views/accounts/accounts.module';
import { OnboardComponent } from './views/onboard/onboard.component';
import { PmsOpsComponent } from './views/pms-ops/create-pms-ops/pms-ops.component';
import { PmsOpsDataComponent } from './views/pms-ops/pms-ops-data/pms-ops-data.component';
import { BanquetComponent } from './views/banquets/banquet/banquet.component';
import { BanquetBookingPosPipe } from './views/banquets/banquet-booking-pos.pipe';
import { CreateBanquetReservationComponent } from './views/banquets/create-banquet-reservation/create-banquet-reservation.component';
import { ClickOutsideDirective } from './_shared/directives/click-outside.directive';
import { MultiSelectDropdownComponent } from './_shared/components/multi-select-dropdown/multi-select-dropdown.component';
import { BulkOutOfOrderComponent } from './views/reservationdata/bulk-out-of-order/bulk-out-of-order.component';
import { DashboardModule } from './views/dashboard/dashboard.module';
import { SelectPrinterComponent } from './_shared/common_modals/select-printer/select-printer.component';
import { VendorsDropDownComponent } from './_shared/components/vendors-drop-down/vendors-drop-down.component';
import { OrderComponent } from './_shared/common_modals/order/order.component';
import { GetPaymentFileComponent } from './views/expenses/get-payment-file/get-payment-file.component';
import { AddGstComponent } from './views/expenses/add-gst/add-gst.component';
import { GuestSearchTableComponent } from './views/reservationdata/guest-search-table/guest-search-table.component';
import { CompanySearchTableComponent } from './views/reservationdata/company-search-table/company-search-table.component';
import { SendPaymentLinkComponent } from './_shared/common_modals/send-payment-link/send-payment-link.component';
import { ShowPaymentLinksComponent } from './_shared/common_modals/show-payment-links/show-payment-links.component';
import { CommentDialogComponent } from './_shared/comment-dialog/comment-dialog.component';
import { PaymentLinksModule } from './views/payment-links/payment-links.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    DialogComponent,
    SnackbarComponent,
    ReservationComponent,
    ReservationdataComponent,
    EditStayComponent,
    ServiceDetailsComponent,
    PaymentsDetailsComponent,
    SpinnerComponent,
    ExpensesComponent,
    AllocationServicesComponent,
    ContactlessCheckinComponent,
    AdditionalGuestComponent,
    AddServiceComponent,
    LogsComponent,
    ChargeCardComponent,
    LineChartComponent,
    EditBookingIdComponent,
    EmployeesComponent,
    AttendanceComponent,
    SalaryComponent,
    HrHomeComponent,
    CreateEmployeeComponent,
    OnboardComponent,
    PmsOpsComponent,
    PmsOpsDataComponent,
    BanquetComponent,
    BanquetBookingPosPipe,
    CreateBanquetReservationComponent,
    ClickOutsideDirective,
    MultiSelectDropdownComponent,
    BulkOutOfOrderComponent,
    SelectPrinterComponent,
    VendorsDropDownComponent,
    OrderComponent,
    GetPaymentFileComponent,
    AddGstComponent,
    GuestSearchTableComponent,
    CompanySearchTableComponent,
    SendPaymentLinkComponent,
    ShowPaymentLinksComponent,
    CommentDialogComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule, AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatCardModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule,
    MatButtonModule, MatTableModule, MatSelectModule, MatButtonToggleModule,
    MatSidenavModule, MatListModule, MatToolbarModule, MatIconModule,
    MatMenuModule, HttpClientModule, MatExpansionModule, MatDialogModule, MatSnackBarModule,
    FormsModule, ReactiveFormsModule, StayviewModule, EditModule, GuestsModule, BookingDetailsModule, CompaniesModule,
    PosModule, MatProgressSpinnerModule, GroupBookingModule, MaintenanceModule,
    ReportsModule, PosReportsModule, RoomviewModule, StoreModule,SignaturePadModule, NgSelectModule,
    MatTooltipModule,
    Stayview2Module,
    ChartsModule,
    LeadsModule,
    AccountsModule,
    DashboardModule,
    PaymentLinksModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    })
  ],
  
  entryComponents: [
    DialogComponent, EditStayComponent, ServiceDetailsComponent, PaymentsDetailsComponent
  ],
  providers: [Title, DatePipe,
    Globals,
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
