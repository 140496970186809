<div>
    <div class="heading">
        <h2>POS Reports</h2>
        <button  mat-stroked-button class="btn btn-info btn-sm pull-right" (click)="goBack()"> Back <mat-icon>forward</mat-icon></button>
    </div>
    <mat-divider></mat-divider>
    <div class="container-fluid">
        <div class="row pt-1 pb-2 bg-white">
            <div  class="col-sm-auto">
                <label for="mealplan">Select POS: </label>
                <select class="form-control" id="report" 
                [(ngModel)] ='posName' (change)="onSelect(selectPosReport);">
                    <option *ngFor="let posValue of posNames" [value]=posValue>
                        {{posValue}}
                    </option>
                </select>
            </div>
            <div  class="col-sm-auto">
                <label for="mealplan">Select Report: </label>
                <select class="form-control" id="report" 
                [(ngModel)] ='selectPosReport' (change)="onSelect(selectPosReport);">
                    <option *ngFor="let report of reportsList" [value]=report>
                        {{report}}
                    </option>
                </select>
            </div>
            <ng-container>
                <div class="col-sm-auto">
                    <label for="From Date">{{'reports.from_date' | translate}}:</label>
                    <input type="date" class="form-control" id="from-date" max="{{toDate}}" [(ngModel)]="fromDate">
                </div>
                <div class="col-sm-auto">
                    <label for="To Date">{{'reports.to_date' | translate}}:</label>
                    <input type="date" class="form-control" id="to-date" min="{{fromDate}}" [(ngModel)]="toDate">
                </div>
            </ng-container>
            <div class="col-sm-auto">
                <br>
                <button class="btn btn-info" (click)="getOrders();">{{'reports.submit' | translate}}</button>
            </div>
            <div class="col-sm-auto ml-auto"></div>
            <div>
                <br>
                <label *ngIf="reportData && reportData.totalAmount">Total Amount : {{reportData.totalAmount}} </label> 
            </div>
            <div class="col-sm-auto ml-auto"></div>
            <div>
                <br>
                <button  mat-stroked-button class="active small-btn" (click)="exportexcel()">{{'reports.download_excel' | translate}} <mat-icon>download </mat-icon> </button>
            </div>
        </div>
        
        <div class="content">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData?.length > 0" ><b>{{selectPosReport}}</b></h3>
                    <h3 *ngIf="reportData && reportData?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b></h3>
                </div>
                <div class="table-bordered table-responsive" *ngIf="selectPosReport == 'Item Consumption' && reportData && reportData?.length > 0">
                    <table class="table table-bordered table-striped" id="excel-table">
                        <thead class="thead-dark">
                            <tr>
                                <th>{{'pos.service_name' | translate}}</th>
                                <th class="text-center">{{'pos.qty' | translate}}</th>
                                <th class="text-right">{{'pos.amount' | translate}}</th>
                                <th class="text-right">{{'pos.tax' | translate}}</th>
                                <th class="text-right">{{'pos.total' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let service of reportData">
                                <td>{{service.service_name}}</td>
                                <td class="text-center">{{service.quantity}}</td>
                                <td class="text-right">{{service.service_amount | number: '1.2-2'}}</td>
                                <td class="text-right">{{service.tax | number: '1.2-2'}}</td>
                                <td class="text-right">{{service.total_amount | number: '1.2-2'}}</td>
                            </tr>
                            <tr [ngStyle]="{'font-size': '0.85rem'}">
                                <td colspan="2" class="text-right">{{'pos.total' | translate}}</td>
                                <td class="text-right">{{serviceTotal.amount | number: '1.2-2'}}</td>
                                <td class="text-right">{{serviceTotal.tax | number: '1.2-2'}}</td>
                                <td class="text-right">{{serviceTotal.total | number: '1.2-2'}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div class="table-bordered table-responsive"  *ngIf="selectPosReport == 'Payments' && reportData && reportData?.length > 0">
                    <table class="table table-bordered table-striped"  id="excel-table">
                        <thead class="thead-dark">
                            <tr>
                                <th>{{'pos.type_of_payment' | translate}}</th>
                                <th class="text-right">
                                    {{'pos.amount' | translate}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of reportData">
                                <td>{{payment.type_of_payment}}</td>
                                <td class="text-right">{{payment.amount | number: '1.2-2'}}</td>
                            </tr>
                            <tr [ngStyle]="{'font-size': '0.85rem'}">
                                <td class="text-right">{{'pos.total' | translate}}</td>
                                <td class="text-right">{{paymentsTotal.amount | number: '1.2-2'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="mid-section bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.body?.length > 0" ><b>{{selectPosReport}}</b></h3>
                    <h3 *ngIf="reportData && reportData.body?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b></h3>
                </div>
                <div class="table-bordered table-responsive"  *ngIf="selectPosReport != 'Payments' && selectPosReport != 'Item Consumption' && reportData && reportData.body?.length > 0">
                    <table class="table table-bordered table-striped"  id="excel-table">
                        <thead class="thead-dark">
                            <tr>
                                <th *ngFor="let header of reportData.headers">{{header}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let paymentRow of reportData.body">
                                <td *ngFor="let payment of paymentRow" >
                                    <p *ngIf="!payment.info">{{payment.value}}</p>
                                    <span *ngIf="payment.info" role="button" class="cell" [matMenuTriggerFor]="amountDetails" >
                                        <span class="cell_info bg_primary">{{payment.value}}</span>    
                                    <mat-menu #amountDetails="matMenu"> 
                                        <div>
                                            <table class="table-bordered table-responsive">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th>Service Name </th>
                                                        <th>Amount </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let info of payment.info">
                                                        <td>{{info.name}}</td>
                                                        <td>{{info.amount}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </mat-menu> 
                                </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
