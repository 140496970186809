import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  sanitizedHTML: SafeHtml;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {}


  ngOnInit(): void {
    if (this.data.innerHTML) {
      this.sanitizedHTML = this.sanitizer.bypassSecurityTrustHtml(this.data.innerHTML);
    }
  }

}
