<ng-container *ngIf="mode === 'editStay'">
    <div class="view-container">
        <table class="table table-striped">
            <thead class="thead-dark">
                <tr class="text-center">
                    <th>{{'stayview.payment_detail.date' | translate}}</th>
                    <th>{{'stayview.payment_detail.type_of_payment' | translate}}</th>
                    <th>{{'stayview.payment_detail.amount' | translate}}</th>
                    <th>{{'stayview.payment_detail.bill_to_company' | translate}}</th>
                    <th>{{'stayview.payment_detail.payment_details' | translate}}</th>
                    <th>{{'stayview.payment_detail.comments' | translate}}</th>
                    <th>{{'stayview.payment_detail.action' | translate}}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of payments" class="text-center">
                    <td>{{payment.date | date : 'MMM d'}}</td>
                    <td>
                        <div class="d-flex justify-content-center align-items-center"
                            matTooltip="
                                {{payment?.credit_card_payment?.status === 'captured' ? 'Payment' : 'Card'}} {{payment?.credit_card_payment?.status}}
                                Card type - {{payment?.credit_card_payment?.payload?.cardType}}
                                Ref - {{payment?.credit_card_payment?.payload?.referenceNumber}}"
                                matTooltipClass="my-tooltip"
                                [matTooltipDisabled]="!payment?.credit_card_payment">
                            <span>{{payment.type_of_payment}}</span>
                            <mat-icon *ngIf="['charged', 'captured'].includes(payment?.credit_card_payment?.status)"
                                class="text-success">
                                done
                            </mat-icon>
                            <mat-icon *ngIf="payment?.credit_card_payment?.status === 'authorized'"
                                class="text-warning">
                                new_releases
                            </mat-icon>
                        </div>
                    </td>

                    <td>{{payment.amount | currency: currency}}</td>
                    <td>{{payment.bill_to_company}}</td>
                    <td><a href={{payment.details}} target="_blank">
                            {{getFileName(payment.details)}}
                        </a>
                    </td>
                    <td>{{payment.comments}}</td>
                    <td class="actions d-flex justify-content-end align-items-center">
                      <input type="file" #fileInput style="display: none"
                        accept="image/*" (change)="updateImage($event.target, payment)">
                        <mat-icon (click)="fileInput.click()">
                          cloud_upload
                        </mat-icon>
                        <button *ngIf="payment?.credit_card_payment?.status === 'authorized'"
                            mat-stroked-button
                            (click)="capturePayment(payment.credit_card_payment)"
                            [ngClass]="{disable_btn: capturing}"
                            matTooltip="Capture this payment">
                            {{'stayview.payment_detail.capture_btn' | translate}}
                        </button>
                        <mat-icon *ngIf="status !== 'Checked out' && !payment?.credit_card_payment?.status"
                            (click)="openPopuptoEnterReason(payment.payment_id, payment.amount)"
                            class="text-danger"
                            [ngClass]="{'disable_button': !features?.live?.deletepayment}"
                            >
                            delete
                        </mat-icon>
                    </td>
                    <td>
                        <ng-container *ngIf="!payment.user && !payment.loading">
                            <mat-icon (click)="getUser(payment)"
                                matTooltip="Click to see user who added this payment">
                                account_circle
                            </mat-icon>
                        </ng-container>
                        <ng-container *ngIf="payment.loading">
                            <span class="spinner-border spinner-border-sm" mat-raised-button role="status" aria-hidden="true"></span>
                        </ng-container>
                        <ng-container *ngIf="payment.user">
                            {{payment.user}}
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="action-buttons">
            <button mat-raised-button class="confirm-button"
                (click)="getReceipt()">
                {{'stayview.payment_detail.receipt_btn' | translate}}
            </button>
            <button *ngIf="status === 'Checked in' || status === 'Assigned' || status === 'Unassigned'"
                mat-raised-button
                (click)="changeMode('newEntry')" 
                class="confirm-button ml-1"
                [ngClass]="{'disable_button': !features?.live?.addPayment}"
                >
                {{'stayview.payment_detail.add_payment' | translate}}
            </button>
        </div>
    </div>
</ng-container>

<!----------------------------------------------NEW ENTRY------------------------------------------------------------- -->

<ng-container *ngIf="mode === 'newEntry'">
    <div class="main_wrapper">
      <!-- EMV Loader -->
      <div id="loading-wait" *ngIf="evmProcessing">
        {{'stayview.payment_detail.text_1' | translate}} <br>
        {{'stayview.payment_detail.text_2' | translate}}
      </div>
      <div class="add-service" (click)="closeCheckBox($event)" [ngClass]="{blur: evmProcessing}">

        <div class="row" [ngStyle]="{'justify-content': 'space-between'}">
          <h1>
            <ng-container *ngIf="data?.heading; else defaultBlock">{{data.heading}}</ng-container>
            <ng-template #defaultBlock>{{'stayview.payment_detail.add_payment' | translate}}</ng-template>
          </h1>
          <button mat-raised-button class="confirm-button" *ngIf="!(data.hasOwnProperty('paidIn')) && config?.qrCode"
            (click)="openWindow(config.qrCode)" [ngStyle]="{'transform': 'scale(0.8)'}">
            {{'stayview.payment_detail.scan_qr_code' | translate}}
          </button>
          <hr>
        </div>
        <div class="add-service-form">

          <form [formGroup]="addPaymentForm" (ngSubmit)="submit(addPaymentForm)">
            <div class="row">
              <div class="col-sm-4">
                <label for="typeofpayment">{{'stayview.payment_detail.type_of_payment' | translate}}</label>
                <select class="form-control" id="payment_type" name="type" formControlName="type_of_payment"
                  (change)="paymentModeChange()">
                  <option value="" hidden>{{'stayview.payment_detail.select_option' | translate}}</option>
                  <option *ngFor="let mode of paymentModes" [value]="mode">
                    {{mode}}
                  </option>
                  <option *ngIf="data.pos && data.billed===false" value="Transfer to Room">{{'stayview.payment_detail.transfer_to_room' | translate}}</option>
                  <option *ngIf="data.pos && data.billed" value="Bill to Room">{{'stayview.payment_detail.add_to_room_bill' | translate}}</option>
                </select>
              </div>
              
              <div class="col-sm-4" *ngIf="addPaymentForm.controls['type_of_payment'].value=='Transfer to Room'">
                <label for="serivce">{{'stayview.payment_detail.service' | translate}}</label>
                <div class="selectBox" (click)="showCheckBox= !(showCheckBox)">
                  <select class="form-control">
                    <option>{{'stayview.payment_detail.select_services' | translate}}</option>
                  </select>
                  <div class="overSelect"></div>
                </div>
                <div class="check-box" *ngIf="showCheckBox" (click)="$event.stopPropagation();">
                  <ul class="check-box-list">
                    <li *ngFor="let service of servicesUsed">
                      <div class="row" [ngStyle]="{'padding': '5px 3px'}">
                        <div class="col-sm-2">
                          <input type="checkbox" [(ngModel)]="service.checked" [ngModelOptions]="{standalone: true}"
                            (change)="amountCalc($event.target)" [value]="service.total_amount">
                        </div>
                        <div class="col-sm-3">
                          <small>{{service.service_date | date : 'MMM d'}}</small>
                        </div>
                        <div class="col-sm-4 ellip" style="padding-right: 0;">
                          <small>{{service.service_name}}</small>
                        </div>
                        <div class="col-sm-3 text-right" style="padding-right: 3px;">
                          <small class="text-success">{{service.total_amount}}</small>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-4">
                <label for="Category">{{'stayview.payment_detail.amount' | translate}}</label>
                <input type="number" class="form-control" id="amount" [(ngModel)]="totalAmount" formControlName="amount"
                  [readonly]="addPaymentForm.controls['type_of_payment'].value=='Transfer to Room' || addPaymentForm.controls['type_of_payment'].value=='Bill to Room'">
              </div>
              <ng-container *ngIf="expenseCategories?.length">
                <div class="col-sm-4">
                  <label for="expense category">{{'stayview.payment_detail.select_category' | translate}}</label>
                  <select class="form-control" formControlName="expenseCategory">
                    <option hidden>{{'stayview.payment_detail.select' | translate}}</option>
                    <option *ngFor="let category of expenseCategories">
                      {{category.name}}
                    </option>
                  </select>
                </div>
              </ng-container>
              <div *ngIf="!data.pos && invoiceDetails?.length > 1" class="col-sm-4">
                <label for="invoice">{{'stayview.payment_detail.select_invoice' | translate}}</label>
                <select class="form-control" formControlName="invoice_id" (change)="invoiceChange($event.target.value)">
                  <option *ngFor="let invoice of invoiceDetails; let i=index" [value]="invoice.invoice_id">
                    {{invoice.invoice_num ? invoice.invoice_num : 'Invoice - ' + (i+1)}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-4">

              <!-- BTC DROPDOWN -->
              <div *ngIf="addPaymentForm.controls['type_of_payment'].value=='Bill to Company'" class="col-sm-4">
                <label for="company">{{'stayview.payment_detail.select_company' | translate}}</label>

                <ng-select (change)="selectCompany($event)" class="form-control form-control-sm">
                  <ng-option *ngFor="let item of companyList; let i=index" [value]="item">
                    <div [ngStyle]="i%2 === 0 && {'background': '#E7E9EB'}">
                      {{item}}
                    </div>
                  </ng-option>
               </ng-select>
              </div>

              <div class="col-sm-4"
                *ngIf="addPaymentForm.controls['type_of_payment'].value=='Transfer to Room' || addPaymentForm.controls['type_of_payment'].value=='Bill to Room'">
                <label for="company">{{'stayview.payment_detail.select_room' | translate}}</label>
                <!-- <input type="text" list="checkedInGuests" class="form-control" (click)="$event.target.value = ''"
                  (change)="getInvoiceIdofGuest($event.target.value);">
                <datalist id="checkedInGuests">
                  <option *ngFor="let guest of checkedInGuests">
                    
                  </option>
                </datalist> -->

                <ng-select class="form-control" (change)="guestChange($event)">
                  <ng-option 
                    *ngFor="let guest of checkedInGuests"
                    [value]="guest">
                    {{guest.room_num}} - {{guest.guest_name}}
                  </ng-option>
                </ng-select>

                <ng-container *ngIf="errMsg.error">
                  <span class="text-danger">{{errMsg.msg}}</span>
                </ng-container>
              </div>

              <ng-container *ngIf="data.expense && data.heading !== 'Deposit'">
                <div class="col-sm-4 mb-2">
                  <label for="expense date">{{'stayview.payment_detail.date' | translate}}</label>
                  <input type="date" class="form-control" formControlName="expenseDate" (keypress)="returnFalse()">
                </div>
              </ng-container>
              <ng-container *ngIf="data.expense && data.heading !== 'Deposit'">
                <div class="col-sm-4">
                  <label for="expense invoice">{{'stayview.payment_detail.invoice_no' | translate}}.</label>
                  <input type="text" class="form-control" formControlName="expenseInvoiceNum" maxlength="64">
                </div>

                <div class="col-sm-4" *ngIf="showVendor">
                  <label for="expense invoice">Vendor</label>
                  <app-vendors-drop-down 
                    [hotelId]="hotelId" 
                    (vendorSelected)="setVendor($event)">
                  </app-vendors-drop-down>
                </div>
              
              </ng-container>
              <div class="col-sm-4">
                <label for="comments">{{'stayview.payment_detail.comments' | translate}}</label>
                <textarea rows="4" class="form-control" name="comments" id="comment" formControlName="comments"></textarea>
              </div>
              <div class="col-sm-4">
                <label for="details">{{'stayview.payment_detail.details' | translate}}</label>
                  <div class="common_browse_file">
                    <input type="hidden" class="" value="" name="" maxlength="30">
                    <input type="file" class="custom-file-input" name="busi-for-doc"
                      accept="image/*" (change)="fileChange($event.target)">
                    <label class="doc-name">
                      <span>{{newFile?.name}}</span>
                    </label>
                  </div>
                <!-- <input type="file" name="details" id="details" (change)="fileChange($event.target)"> -->
              </div>

              <ng-container *ngIf="data.expense && 
                                  data.heading !== 'Deposit' && 
                                  config?.tdsForExpenses &&
                                  addPaymentForm.controls['tds_classification'].value">
                
                <div class="col-sm-1" >
                  <label for="tds">TDS %</label>
                  <input type="number" class="form-control" formControlName="tds_perc">
                </div>
  
                <div class="col-sm-3">
                  <label for="classification">TDS Classification</label>
                  <select name="classification" class="form-control" formControlName="tds_classification">
                    <option *ngFor="let cl of tdsClassifications" 
                      [value]="cl">
                      {{cl}}
                    </option>
                  </select>
                </div>

              </ng-container>

              <div class="col-sm-4" *ngIf="invoiceAmount !== undefined">
                <label for="amount">{{'stayview.payment_detail.invoice_total' | translate}} :</label>
                <span class="text-success h6"> {{invoiceAmount | number: '1.2-2'}}</span>
              </div>
            </div>
            <div class="row"
              *ngIf="addPaymentForm.value.type_of_payment==='Bill to Company' && addPaymentForm.value.bill_to_company===''">
              <small class="text-danger">{{'stayview.payment_detail.please_select_company' | translate}}</small>
            </div>

            <!-- FOOTER SECTION -->
            <div class="mt-4 footer_row">

              <!-- Credit card options start-->
              <div class ="align-left" *ngIf="!data?.expense && config?.creditCardInterface?.isEnabled">

                <ng-container *ngIf="(addPaymentForm.value.type_of_payment === 'Credit Card' || addPaymentForm.value.type_of_payment === 'Debit Card' || addPaymentForm.value.type_of_payment === 'Wallet' ||
                addPaymentForm.value.type_of_payment === 'UPI' || addPaymentForm.value.type_of_payment === 'GooglePay' || addPaymentForm.value.type_of_payment === 'PayTM' ||
                addPaymentForm.value.type_of_payment === 'PhonePe')">

                  <ng-container *ngIf="config.creditCardInterface.emvIsEnabled && config.creditCardInterface.emvDeviceId && config.creditCardInterface.providerName == 'heartland'">
                    <button (click)="chargeEmv()" type="button" mat-raised-button>
                      {{'stayview.payment_detail.charge_emv' | translate}}
                    </button>

                    <button (click)="authorizeEmv()" type="button" mat-raised-button>
                      {{'stayview.payment_detail.authorize_emv' | translate}}
                    </button>
                  </ng-container>
                  <ng-container *ngIf="config.creditCardInterface.providerName == 'heartland'">
                    <button (click)="openChargeCard('charge')" type="button" mat-raised-button>
                      {{'stayview.payment_detail.charge_card' | translate}}
                    </button>

                    <button (click)="openChargeCard('authorize')" type="button" mat-raised-button>
                      {{'stayview.payment_detail.authorize_card' | translate}}
                    </button>
                  </ng-container>
                  <ng-container id="pinelabsButtons" *ngIf="config.creditCardInterface.providerName == 'pinelabs'">
                    <ng-container>
                      <button (click)="initiatePayment()" type="button" *ngIf = "paymentInitiated != true" mat-raised-button>
                        {{'stayview.payment_detail.initiate_on_edc' | translate}}
                      </button>
                      <button [disabled] = "true" *ngIf = "paymentInitiated == true" type="button" mat-raised-button>
                        {{'stayview.payment_detail.sent_to_edc' | translate}}
                      </button>
                    </ng-container>
                    <ng-container>
                      <button (click)="getPaymentStatus()" *ngIf = "paymentStatusConfirm != true && gettingStatus != true && paymentStatusFailed != true" type="button" mat-raised-button>
                        {{'stayview.payment_detail.get_status' | translate}}
                      </button>
                      <button [disabled] = "true" *ngIf = "gettingStatus == true" type="button" mat-raised-button>
                        {{'stayview.payment_detail.getting_status' | translate}}
                      </button>
                      <button [disabled] = "true" *ngIf = "paymentStatusConfirm == true" type="button" mat-raised-button>
                        {{'stayview.payment_detail.confirmed' | translate}}
                      </button>
                      <button [disabled] = "true" *ngIf = "paymentStatusFailed == true" type="button" mat-raised-button>
                        {{'stayview.payment_detail.failed' | translate}}
                      </button>
                    </ng-container>
                    <button (click)="cancelTransaction()" type="button" mat-raised-button>
                      {{'stayview.payment_detail.cancel_on_edc' | translate}}
                    </button>
                  </ng-container>
                </ng-container>

                <!-- PAYTM -->
                <ng-container *ngIf="config.creditCardInterface.providerName == 'paytm' &&
                  config.creditCardInterface?.paytm?.paymentModes?.length &&
                  config.creditCardInterface?.paytm?.paymentModes.includes(addPaymentForm.value.type_of_payment)">

                  <button 
                    (click)="initiatePaytmPayment()" type="button"
                    mat-raised-button>
                    {{'stayview.payment_detail.initiate_on_edc' | translate}}
                  </button>

                  <button [disabled]="true" *ngIf="paymentInitiated" type="button" mat-raised-button>
                    {{'stayview.payment_detail.sent_to_edc' | translate}}
                  </button>

                  <button (click)="getPaytmStatus()" 
                    *ngIf="paymentInitiated" 
                    type="button" mat-raised-button>
                    {{'stayview.payment_detail.get_status' | translate}}
                  </button>
                </ng-container>

              </div>
              <!-- Credit card options end-->

              <div class="footer">
                <div>
                  <button *ngIf="!newEntryplusView" mat-raised-button mat-dialog-close>
                    {{'stayview.payment_detail.cancel' | translate}}
                  </button>
                  <button *ngIf="newEntryplusView" (click)="changeMode('editStay')" mat-raised-button>{{'stayview.payment_detail.cancel' | translate}}
                  </button>
                  <button type="submit" mat-raised-button mat-dialog-close
                    [disabled]="formInvalid"
                    class="confirm-button">
                    {{'stayview.payment_detail.submit' | translate}}
                  </button>
                </div>

                <div>
                  <button *ngIf="data.expense && 
                    config?.gstForExpenses &&
                    data.heading !== 'Deposit'"
                    type="button"
                    mat-raised-button
                    (click)="openAddGstDialog()">
                    Add GST
                  </button>
                </div>
              
              </div>
              
            </div>
          </form>
        </div>
      </div>

    </div>
</ng-container>

<!----------------------------------------------Entry for Reason of deletion box----------------------------------->

<ng-container  *ngIf="data.mode === 'reason'">
    <div >
        <h1 class="row deleteReason">{{'stayview.payment_detail.delete_reason' | translate}}
        </h1>
        <hr>
    </div>
    <ng-container >
        <div class="col-sm-10 mb-2 deleteReason">
            <label for="reasontext">{{'stayview.payment_detail.reason' | translate}}</label>
            <input type="text" class="form-control" [(ngModel)]="reasonOfDeletion" id="reasontext">
        </div>
    </ng-container>
    <div class="row action-buttons mt-4">
        <button mat-dialog-close
            mat-raised-button>{{'stayview.payment_detail.cancel' | translate}}
        </button>
        <button type="submit" mat-raised-button mat-dialog-close="true"
            [disabled]="reasonOfDeletion==''"
            class="confirm-button" (click)="deletePayment(reasonOfDeletion, data.paymentId, data.amount, data.allocId, data.invoiceId)">
            {{'stayview.payment_detail.submit' | translate}}
        </button>
    </div>
</ng-container>