<div class="edit-stay-page">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Edit Stay">
            <div class="edit-stay">
                <app-reservation [mode]="mode" [allocId]="allocId" [hoteliD]="hoteliD" [editStayAccess]="editStayAccess" [showDeleteReservation]="showDeleteReservation" [noExtendOrReduceStay] = "noExtendOrReduceStay"></app-reservation>
            </div>           
        </mat-tab>
        <mat-tab label="Services">
            <div class="services">
                <app-service-details [mode]="mode" [allocId]="allocId" [status]="data.status" [hoteliD]="hoteliD" 
                    (invoiceCount)="setInvoiceCount($event)"
                    (invoiceIds)="invoiceIdEvent($event)">
                </app-service-details>
            </div>
        </mat-tab>
        <mat-tab label="Payments">
            <div class="payments">
                <app-payment-details [mode]="mode" [allocId]="allocId" [status]="data.status" [hoteliD]="hoteliD"></app-payment-details>
            </div>
        </mat-tab>
        <mat-tab disabled>
            <ng-template mat-tab-label>

                <button *ngIf="(data.status ==='Checked in' && roomAllocationData?.balance > 0) || data.status=='Assigned' || data.status=='Unassigned'"
                    mat-button class="a-button mr-1" 
                    (click)="sendPaymentLink()">
                    Send Payment Link
                </button>

                <button mat-button class="a-button mr-1" 
                *ngIf="data.status=='Assigned' || data.status=='Unassigned'"
                (click)="viewServices()">
                {{'stayview.edit_stay.services' | translate}}
                </button>
                <button mat-button class="a-button mr-1" 
                *ngIf="data.status!=='Out of Order'"
                (click)="viewVoucher()">
                {{'stayview.edit_stay.booking_voucher' | translate}}
                </button>
                <button mat-button mat-dialog-close class="a-button mr-1" 
                *ngIf="data.status!=='Checked in' && data.status!=='Checked out' && data.status!=='Out of Order' &&  groupBooking == false"
                (click)="editReservation()">
                {{'stayview.edit_stay.edit_reservation' | translate}}
                </button>
                <button *ngIf="(data.status==='Checked in' || data.status==='Checked out') && invoiceCount > 1"
                    (click)="getGroupInvoice()"
                    mat-button class="a-button mr-1">
                    {{'stayview.edit_stay.summary_invoice' | translate}}
                </button>

                <div id="invoice_button" *ngIf="['Checked in', 'Checked out', 'Assigned', 'Unassigned'].includes(data.status)">
                    <button (click)="getInvoice(data.status)"
                        mat-button class="a-button mr-1">
                        {{'stayview.edit_stay.invoice' | translate}}
                    </button>
                    <mat-icon>
                        attach_money
                    </mat-icon>
                    
                    <input list="downloadableCurrency" class="form-control form-control-sm" id="currency_input"
                        [(ngModel)]="selectedCurrency"
                        (click)="selectedCurrency = ''"
                        (ngModelChange)="downloadInvoice()">
                    <datalist id="downloadableCurrency">
                        <option
                            *ngFor="let currency of supportedCurrencies" 
                            [value]="currency">
                    </datalist>
                </div>

                <button mat-button mat-dialog-close class="a-button">
                    <mat-icon>keyboard_backspace</mat-icon> {{'stayview.edit_stay.back_btn' | translate}}
                </button>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
