import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReservationService } from 'src/app/core/reservation.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-swap-room',
  templateUrl: './swap-room.component.html',
  styleUrls: ['./swap-room.component.scss']
})
export class SwapRoomComponent implements OnInit {
  roomAllocsDetails: any;
  currentroomNum = '';
  selecteddata = '';
  submitting = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private guestService: ReservationService,public dialog: MatDialog, private dialogRef: MatDialogRef<SwapRoomComponent>) { }

  ngOnInit(): void {
    this.currentroomNum = this.data.roomNum;
    this.guestService.getCheckedInAllocData(this.data.roomAllocationId, this.data.hotelId).subscribe(result=>{
      this.roomAllocsDetails = result.allocDetails;
    })
  }
  clearField(){
    this.selecteddata = '';
  }

  closeDialog(){
    this.dialogRef.close();
  }
  submit(selectedRoom){
    var info = {}
    for (var room in this.roomAllocsDetails){
      if (this.roomAllocsDetails[room]['roomNum'] == selectedRoom){
        info['allocId1'] = this.data.roomAllocationId;
        info['roomNumId1'] = this.data.roomNumId;
        info['allocId2'] = this.roomAllocsDetails[room]['allocId'];
        info['roomNumId2'] = this.roomAllocsDetails[room]['roomNumId'];
        info['user'] = this.data.user;
      }
    }
    if (Object.keys(info).length>0){
      const dialogBody = {title: 'Exchange Rooms', body: 'Are you sure you want to Submit ?',
            confirmBtn: 'Submit', cancelBtn: 'Cancel'
            };
      const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
      dialogRef.afterClosed().subscribe(result => {
          if(result === 'true'){
            this.submitting = true;
            this.guestService.exchangeRooms(info, this.data.hotelId).subscribe(success=>{
              this.submitting = false;
              success = success;
              var WarningDialog = {};
              if (success.error){
                WarningDialog = {title: 'Error !', body: success['error'], cancelBtn: 'Dismiss'};
                const dialogRef = this.dialog.open(DialogComponent, {data: WarningDialog, width: '400px',
                  height: '160px'});
                dialogRef.disableClose = true;
                dialogRef.afterClosed().subscribe(result => {
                  if (result === 'false'){
                    this.dialog.closeAll();
                    window.location.reload()
                  }
                });
              }else{
                window.location.reload();
              }
            })
        }
      })
    }
    
  }
}
