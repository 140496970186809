<div *ngIf = "showPage == false">
    <mat-icon class="close-button" (click)="closeDialog();">cancel</mat-icon>
    <ng-container>
        <div class="scrollTable">
            <table class="table table-striped" *ngIf = "logs">
                <thead class="thead-dark">
                    <tr class="text-left">
                        <th width="20%">Time</th>
                        <th width="15%">Action</th>
                        <th width="50%">Log</th>
                        <th width="15%">By</th>
                    </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let log of logs" class="text-left">
                        <td>{{log.time | date : 'MMM d, y, h:mm:s a'}}</td>
                        <td>{{log.action_type}}</td>
                        <td>{{log.details}}</td>
                        <td>{{log.by}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>


<div *ngIf = "showPage == true">
    <div class="mat-elevation-z8">
      <div class="form-row">
          <div class="form-group col-md-2 col-sm-2">
            <label for="fromDate">From Date:</label>
            <input type="date" class="form-control form-control-sm" onkeydown="return false" id="fromDate" [(ngModel)]='fromDate' name="fromDate" >
          </div>
          <div class="form-group col-md-2 col-sm-2">
            <label for="toDate">To Date:</label>
            <input type="date" class="form-control form-control-sm" onkeydown="return false" id="toDate" [(ngModel)]='toDate' name="toDate" >
          </div>
          <div class="form-group col-sm-2">
            <label for="posname">POS Name</label>
            <select class="form-control form-control-sm" id="posname" [(ngModel)]="posName">
              <option *ngFor="let name of posNames" [value]="name">
                {{ name }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <button type="button" class="button1 submitButton form-control-sm" (click)="getLogs()">Submit</button>
          </div>
          <div class="form-group col-md-1 ml-auto">
            <button type="button" class="button1 submitButton form-control-sm" (click)="backToPosPage()">Back</button>
          </div>
      </div>
  
      <table mat-table [dataSource]="dataSource" matSort class="table_wrapper">
    
        <ng-container matColumnDef="datetime">
          <th mat-header-cell mat-column-datetime *matHeaderCellDef > Date Time </th>
          <td mat-cell mat-column-datetime *matCellDef="let element"> {{element.datetime | date : 'MMM d, y, h:mm:s a'}} </td>
        </ng-container>
  
        <ng-container matColumnDef="log">
          <th mat-header-cell *matHeaderCellDef > Logs </th>
          <td mat-cell *matCellDef="let element"> {{element.log}} </td>
        </ng-container>
  
        <ng-container matColumnDef="changed_by" sticky>
          <th mat-header-cell mat-column-changed_by *matHeaderCellDef> Changed By </th>
          <td mat-cell mat-column-changed_by *matCellDef="let element"> {{element.changed_by}} </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      
      <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>