import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/core/user.service';
import { SnackbarComponent } from 'src/app/_shared/snackbar/snackbar.component';

@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.scss']
})
export class EditInvoiceComponent implements OnInit {

  editInvoiceForm: FormGroup;
  invoiceIndex = 0;
  taxTextToDisplay = 'GST Number';

  get invoiceForm() {
    return this.editInvoiceForm.get('invoiceForm') as FormArray;
  }

  addInvoiceForm(data) {
    this.invoiceForm.push(this.fb.group({
      invoice_name: [data.invoice_name],
      gst_num: [data.gst_num],
      invoice_address: [data.invoice_address],
      invoice_id: [data.invoice_id],
      state:[data.state],
    }));
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder,
              private dialogRef: MatDialogRef<EditInvoiceComponent>,
              private dialog: MatDialog,
              private userService: UserService,
              public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.fetchConfigInfo();
    this.editInvoiceForm = this.fb.group({
      invoiceForm: this.fb.array([])
    });
    for (const item of this.data.data) {
      this.addInvoiceForm(item);
    }
    
  }

  fetchConfigInfo(){
    this.userService.getProductConfig(this.data.hotelId).subscribe(data => {
      if(data.conf.taxText){
        this.taxTextToDisplay = data.conf.taxText;
      }
    })
  }

  submit(form) {
    const body = {data: form.value.invoiceForm, user: this.data.user};
    if (this.data.status == 'checked-in'){
      this.userService.editInvoiceDetailsforAllocation(body, this.data.roomAllocationId, this.data.hotelId).
      subscribe(
        data => {
          this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'my-custom-snackbar',
          data: 'Invoice edited successfully',
          duration: 4000,
        });
        }
      );
      this.dialog.closeAll();
    }else{
      this.userService.editInvoiceDetails(body, this.data.roomAllocationId, this.data.hotelId).
      subscribe(
        data => {
          this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'my-custom-snackbar',
          data: 'Invoice edited successfully',
          duration: 4000,
        });
        }
      );
      this.dialog.closeAll();
    }
    
  }

  changeInvoice(index) {
    this.invoiceIndex = index;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
